import React from "react";

const Content = ({billOfRight}) => {
  return (
    <div className="max-w-5xl pt-36 md:pt-48 px-10 md:px-2 mx-auto">
      <h1 className="billrightTitle text-center text-3xl md:text-5xl font-bold mb-20">
        {billOfRight.sections[1].mention}
      </h1>
      <p className="text-xl px-0 text-center text-black max-w-3xl mx-auto mb-20">
        {billOfRight.sections[1].description}
      </p>
      {billOfRight.sections[2].billOfRightMultiBlock.map((item, index) => {
        return (
          <div className="mb-10 md:px-48 md:ml-10 mx:auto" key={index}>
            <div className="flex justify-center mb-5">
              <h1 className="w-32 h-32 rounded-full bg-yellow text-black text-7xl pl-11 pt-5 font-bold">
                {item.mention}
              </h1>
            </div>
           
            <h3 className="text-5xl font-bold text-black pb-8">
              {item.mainTitle}
            </h3>
            <p className="text-xl font-normal text-black pb-3">
              {item.description}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default Content;
