import React from "react";
import Header from "./Header";
import Content from "./Content";
import Think from "../home/Think";
import {useStaticQuery, graphql} from "gatsby";
const Index = () => {
  const data = useStaticQuery(graphql`
    query billOfRight {
      allSanityBillOfRights {
        edges {
          node {
            sections {
              ... on SanityBillOfRight {
                _key
                billOfRightMultiBlock {
                  mention
                  title
                  mainTitle
                  description
                }
              }
              ... on SanityContentSection {
                _key
                mention
                description
              }
            }
          }
        }
      }
    }
  `);
  const billOfRight = data.allSanityBillOfRights.edges[0].node;
 
  return (
    <div>
      <Content billOfRight={billOfRight}/>
      <Think />
    </div>
  );
};

export default Index;
