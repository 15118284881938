import React from "react";
import Layout from "../components/Layout";
import Index from "../components/bill-of-rights/Index";
import { Helmet } from 'react-helmet'

const billofrights = () => {
  return (
    <>
    <Helmet>
            <meta charSet="utf-8" />
            <title>Auto Genius - What We Believe</title>
            <meta
              name="description"
              content="At Auto Genius, we believe that automotive marketers should be able to innovate freely to land on what's next. Here's 5 of our beliefs."
            />
          </Helmet>
          <Layout>
          
      <Index />
    </Layout></>
  );
};

export default billofrights;
